<script>
import Vue from "vue";
import appConfig from "@/app.config";
import { with_acrf_token } from '@/methods';
import { FormWizard, TabContent } from "vue-form-wizard";

import VueCryptojs from 'vue-cryptojs';
Vue.use(VueCryptojs);

export default {
  components: {
    FormWizard,
    TabContent,
    PasswordSetupPrompt: () => import("@/components/lazy/account/PasswordSetupPrompt")
  },
  data() {
    return {
      form: null
    };
  },
  created() {

  },
  methods: {
    validateStep(name) {
      let refToValidate = this.$refs[name];
      return refToValidate.validate();
    },
    mergePartialModels(model, isValid) {
      if(isValid) {
        this.form = Object.assign({}, this.form, model)
      }
    },
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/account', {credentials: 'include'})
          .then(response => {
            if(response.ok){
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            this.account = data.account;
            this.ready = true;
          })
          .catch(error => {
            this.handleError(error);
          });
    },
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    reset() {
      this.form = null;
    },
    completed: function() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          let that = this;
          let password = this.form.confirmPassword;
          let password_hashed = this.CryptoJS.SHA256(password).toString(this.CryptoJS.enc.Hex);

          this.$bvModal.hide('setupPassword');

          with_acrf_token().then((r) => {
            let payload = {
              acsrf_token: r.acsrf_token,
              password: password_hashed
            };
            fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/update-password', {
              method: 'POST',
              body: JSON.stringify(payload),
              credentials: 'include'
            })
                .then(response => {
                  if(response.ok){
                    return response.json();
                  } else {
                    if(response.status === 429) {
                      this.$swal({
                        icon: 'warning',
                        text: this.$t('error.server.ratelimit.message'),
                        title: this.$t('error.server.ratelimit.title')
                      });
                    } else
                      throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
                  }
                })
                .then(data => {
                  this.$swal({
                    icon: 'success',
                    text: this.$t('account.security.password.process.success')
                  });
                  this.$emit('passwordEnabled');
                })
                .catch(error => {
                  console.log(`[ERROR] ${error}`);
                  this.$swal({
                    icon: 'error',
                    text: this.$t('account.security.password.process.error.server')
                  });
                });
          }).catch(error => {
            console.log('[ERROR] Failed to request acrf token');
            this.$swal({
              icon: 'error',
              text: this.$t('error.server.generic.message')
            });
          });
          resolve(true);
        }, 1);
      })
    },
  }
};
</script>
<style>
  .wizard-progress-with-circle {
    display: none;
  }
</style>
<template>
  <div class="d-inline-flex">

    <button
        class="btn btn-sm btn-primary"
        v-b-modal.setupPassword
        data-toggle="modal"
        data-target="#setupPassword"
        @click="reset()"
    >
      <i class="fad fa-key-skeleton"></i> {{ $t('account.security.password.setup') }}
    </button>
    <b-modal id="setupPassword" size="md" centered :title="$t('account.security.password.setup')" hide-footer>
      <!-- @on-complete="form_completed" -->
      <form-wizard
          shape="circle"
          errorColor="#f46a6a"
          color="#4285F4"
          @on-complete="completed"
          :start-index="0"
          :next-button-text="$t('terms.next')"
          :back-button-text="$t('terms.back')"
          :finish-button-text="$t('account.security.password.process.confirm')"
      >
        <tab-content icon="fas fa-key-skeleton" :before-change="()=>validateStep('passwordPrompt')">
          <PasswordSetupPrompt ref="passwordPrompt" @on-validate="mergePartialModels"></PasswordSetupPrompt>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>